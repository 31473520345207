import logo from "../Assets/logo.png";

export const useCommonHooks = () => {
  // Function to update the tab title and icon
  const updateTabInfo = (brandDetails) => {
    document.title = brandDetails?.brand_name || "Pinaippu";

    const link = document.querySelector("link[rel~='icon']");
    if (link) {
      link.href = brandDetails?.brand_logo || logo;
    } else {
      const newLink = document.createElement("link");
      newLink.rel = "icon";
      newLink.href = brandDetails?.brand_logo || "/default-icon.ico";
      document.head.appendChild(newLink);
    }
  };

  const updateTabTitle = (count, brandDetails) => {
    document.title = brandDetails?.brand_name || "Default Title";
  };

  return {
    updateTabInfo,
    updateTabTitle,
  };
};
