import { toast } from "react-toastify";
import { useNavigate, useHistory, useLocation } from "react-router-dom";
import UseWindowSize from "./UseWindowSize";
import notification_sound from "../Assets/notification_sound.mp3";
const formatDate = (dateString) => {
  const options = {
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    //timeZone: "UTC",
  };
  return new Date(dateString).toLocaleDateString(undefined, options);
};

export const convertToLocal = (utcTime) => {
  const utcDate = new Date(utcTime);
  const year = utcDate.getFullYear();
  const month = String(utcDate.getMonth() + 1).padStart(2, "0");
  const day = String(utcDate.getDate()).padStart(2, "0");

  const timeOptions = {
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    hour12: false,
  };

  const dateString = `${year}-${month}-${day}`;
  const timeString = utcDate.toLocaleTimeString(undefined, timeOptions);

  return `${dateString} ${timeString}`;
};

export const convertToUTC = (dateTimeString) => {
  const localDateTime = new Date(dateTimeString);
  return localDateTime.toISOString();
};

export const convertToUTCTime = (localTime) => {
  const localDate = new Date();
  const [hours, minutes] = localTime.split(":");
  localDate.setHours(parseInt(hours), parseInt(minutes));

  const utcTimeString = `${localDate.getUTCHours()}:${localDate.getUTCMinutes()}`;
  return utcTimeString;
};

const formatDateOnly = (dateString) => {
  const options = {
    day: "2-digit",
    month: "short",
    year: "numeric",
  };
  return new Date(dateString).toLocaleDateString(undefined, options);
};

const formatTime = (timeString) => {
  const options = {
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    hour12: true,
  };
  return new Date(`1970-01-01T${timeString}`).toLocaleTimeString(
    undefined,
    options
  );
};
const notify = (type, message) => {
  if (type == "success" || type == "Success" || type == "SUCCESS") {
    toast.success(message);
  }
  if (type == "error" || type == "Error" || type == "ERROR") {
    toast.error(message);
  }
  if (type == "warn" || type == "Warn" || type == "WARN") {
    toast.warn(message);
  }
  if (type == "info" || type == "Info" || type == "INFO") {
    toast.info(message);
  }

  // toast.error("Error Notification !", {
  //   position: toast.POSITION.TOP_LEFT,
  // });

  // toast.warn("Warning Notification !", {
  //   position: toast.POSITION.BOTTOM_LEFT,
  // });

  // toast.info("Info Notification !", {
  //   position: toast.POSITION.BOTTOM_CENTER,
  // });

  // toast("Custom Style Notification with css class!", {
  //   position: toast.POSITION.BOTTOM_RIGHT,
  //   className: "foo-bar",
  // });
};

function parseFormattedText(text) {
  if (!text) {
    return null;
  }
  const boldRegex = /\*([^*]+)\*/g;
  const italicRegex = /_([^_]+)_/g;
  const boldItalicRegex = /\*{2}([^*]+)\*{2}/g;
  const newlineRegex = /(\r\n|\r|\n)/g;

  const boldText = text.replace(boldItalicRegex, "<b><i>$1</i></b>");
  const italicText = boldText.replace(italicRegex, "<i>$1</i>");
  const formattedText = italicText.replace(boldRegex, "<b>$1</b>");

  const textWithNewlines = formattedText.replace(newlineRegex, "<br>");

  return <span dangerouslySetInnerHTML={{ __html: textWithNewlines }} />;
}
const RedirectBasedOnDomain = () => {
  const allowedDomain = "pinaippu.io";
  const currentDomain = window.location.hostname;
  if (currentDomain === "localhost") {
    console.log("continue");
  } else if (currentDomain !== allowedDomain) {
    window.location.href = `https://www.${currentDomain}/login`;
  }
};

const isMobile = () => {
  const [screenWidth, screenHeight] = UseWindowSize();
  if (screenWidth < 770) {
    return true;
  } else {
    return false;
  }
};
function playNotificationSound() {
  let perm;
  Notification.requestPermission().then((permission) => {
    if (permission === "granted") {
      perm = true;
    }
  });
  const audio = new Audio("../Assets/notification_sound.mp3"); // Replace with the path to your sound file
  if (perm) audio.play();
}

const arraysAreEqual = (arr1, arr2) => {
  // Comparing arrays
  return JSON.stringify(arr1) === JSON.stringify(arr2);
};
// Function to parse the query parameters and extract the phone number
function extractPhoneNumberFromUrl(url) {
  const urlParams = new URLSearchParams(url);
  return urlParams.get("phone_number");
}
export {
  formatDate,
  notify,
  formatDateOnly,
  parseFormattedText,
  RedirectBasedOnDomain,
  formatTime,
  isMobile,
  playNotificationSound,
  arraysAreEqual,
  extractPhoneNumberFromUrl,
};
