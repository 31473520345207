import { apiFetch } from "./CommonApi";
import { notify } from "../Components/Common";

export const login = (email, password) => {
  return apiFetch(
    "POST",
    "login/",
    {
      email: email,
      password: password,
    },
    false
  ).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      throw new Error("Failed to login");
    }
  });
};

export const social = (provider, accessToken) => {
  let url;
  if (provider === "facebook") {
    url = "dj-rest-auth/facebook/";
  } else if (provider === "google") {
    url = "dj-rest-auth/google/login/";
  }

  if (url && accessToken) {
    return apiFetch("POST", url, { code: accessToken }, false).then(
      (response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Failed to login");
        }
      }
    );
  } else {
    throw new Error("Invalid provider or access token");
  }
};

export const facebookLogin = (accessToken) => {
  const url = "dj-rest-auth/facebook/";
  if (accessToken) {
    return apiFetch("POST", url, { access_token: accessToken }, false).then(
      (response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Failed to login");
        }
      }
    );
  } else {
    throw new Error("Invalid access token");
  }
};

export const logout = () => {
  localStorage.removeItem("userTokens");
  return apiFetch("POST", "dj-rest-auth/logout/", null, true);
};

export const submitDemoRequest = (data) => {
  return apiFetch(
    "POST",
    "request-demo/",
    {
      name: data?.name,
      email: data?.email,
      phone_number: `+${data?.phone_number}`,
    },
    false
  );
};

export const registerUser = (data) => {
  return apiFetch(
    "POST",
    "dj-rest-auth/registration/",
    {
      username: data?.name,
      password1: data?.password1,
      password2: data?.password2,
      email: data?.email,
      phone_number: data?.phone_number,
    },
    false
  );
};

export const checkwhatsAppBusinessAcc = () => {
  return apiFetch("GET", "whatsapp-business/account-details/", false, true)
    .then((res) => {
      if (res.ok) {
        return res.json();
      } else {
        localStorage.removeItem("userTokens");
        // notify("error", "Session expired");
        // window.location.href = "/";
      }
    })
    .then((res) => {
      return res?.whatsapp_accounts;
    })
    .catch((error) => {
      console.log("error", error);
      notify("Error", "something went wrong");
    });
};

export const WpAddAccount = (accessToken) => {
  return apiFetch(
    "POST",
    "/whatsapp-business/add-account/",
    {
      accessToken: accessToken,
    },
    true
  ).then((response) => {
    return response;
  });
};

export const setPassword = (token, newPassword) => {
  return apiFetch(
    "POST",
    `after-accept-invite/${token}/`,
    {
      password: newPassword,
    },
    false
  );
};

export const reSetPassword = (body) => {
  return apiFetch("POST", "dj-rest-auth/password/change/", body, true)
    .then((res) => {
      if (res.ok) {
        notify("success", "Password reset Done");
        return res;
      } else {
        notify("error", "Failed to reset Password");
        throw new Error("Failed to reset Password");
      }
    })
    .catch((error) => {
      console.error("Error", error);
      throw error;
    });
};

export const saveUserDetails = (body) => {
  return apiFetch("POST", "user-details/", body, true)
    .then((res) => {
      if (res.ok) {
        notify("success", "User Details Updated");
        return res;
      } else {
        notify("error", "Failed to Updated User Details");
        throw new Error("Failed to Updated User Details");
      }
    })
    .catch((error) => {
      console.error("Error", error);
      throw error;
    });
};
