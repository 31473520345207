import { apiFetch } from "./CommonApi";
import { notify } from "../Components/Common";

export const loadContacts = (param) => {
  const url = param
    ? `whatsapp-business/contacts/?${param}`
    : "whatsapp-business/contacts/?page=1";
  return apiFetch("GET", url, null, true)
    .then((res) => {
      if (res.ok) {
        return res.json();
      } else {
        console.error(res);
        throw new Error("Something went wrong with contacts");
      }
    })
    .catch((error) => {
      console.error("Error", error);
      throw error;
    });
};

export const UpdateAttribute = (data, attributes) => {
  const body = {
    name: data?.name,
    email: data?.email,
    custom_attribute: attributes,
  };
  console.log("Patch Update body", body);
  return apiFetch(
    "PATCH",
    `whatsapp-business/contacts/${data.id}/`,
    body,
    true
  ).then((res) => {
    if (res.ok) {
      return res.json();
    } else {
      console.error(res);
      notify("error", "Something went wrong with Updation");
    }
  });
};

export const deleteAttribute = (attrData) => {
  const body = {
    value: null,
  };

  return apiFetch(
    "PUT",
    `whatsapp-business/attributes/${attrData.id}/`,
    body,
    true
  )
    .then((res) => {
      if (res.ok) {
        return res;
      } else {
        throw new Error("Attribute not deleted");
      }
    })
    .catch((error) => {
      console.error("Error", error);
      throw error;
    });
};

export const deleteContact = (contactId) => {
  return apiFetch(
    "DELETE",
    `whatsapp-business/contacts/${contactId}/`,
    null,
    true
  )
    .then((res) => {
      if (res.ok) {
        return res;
      } else {
        throw new Error("Contact not deleted");
      }
    })
    .catch((error) => {
      console.error("Error", error);
      throw error;
    });
};

export const createContact = (contactData) => {
  return apiFetch("POST", "whatsapp-business/contacts/", contactData, true)
    .then((res) => {
      if (res.ok) {
        return res;
      } else {
        // throw new Error("Failed to create contact");
        return res;
      }
    })
    .catch((error) => {
      console.error("Error", error);
      throw error;
    });
};

export const updateContact = (data, editData, attributes) => {
  const getAttributeIDWithName = (name) => {
    const attribute = attributes.find((attr) => attr.name === name);
    return attribute ? attribute.id : null;
  };

  const { name, phone_number, email, ...custom_attributes } = data;
  let final;

  if (editData.custom_attribute.length !== 0) {
    final = Object.entries(custom_attributes).map(([key, value]) => {
      const option = editData.custom_attribute.find(
        (option) => option.custom_field === key
      );

      if (!option) {
        throw new Error(`Custom attribute "${key}" not found in editData.`);
      }
      return {
        id: option.id,
        custom_field: getAttributeIDWithName(option.custom_field),
        value: value ? value : null,
        type: option.type,
      };
    });
  } else {
    final = Object.entries(custom_attributes).map(([key, value]) => {
      return {
        custom_field: getAttributeIDWithName(key),
        value: value ? value : null,
      };
    });
  }

  const body = {
    name: data?.name,
    email: data?.email,
    custom_attribute: final,
  };
  console.log("Put Update body", body);

  return apiFetch(
    "PUT",
    `whatsapp-business/contacts/${editData.id}/`,
    body,
    true
  ).then((res) => {
    if (res.ok) {
      return res.json();
    } else {
      console.error(res);
      notify("error", "Something went wrong with contacts");
    }
  });
};

const downloadFile = (blob, fileName) => {
  const downloadUrl = URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = downloadUrl;
  link.download = fileName; // Set the file name dynamically
  document.body.appendChild(link); // Append the link to the DOM
  link.click(); // Trigger the download
  link.remove(); // Remove the link element after download
  URL.revokeObjectURL(downloadUrl); // Revoke the object URL after download
};

export const downloadSampleFile = async () => {
  const url = `whatsapp-business/contacts/generate-sample/`;

  try {
    const res = await apiFetch("GET", url, null, true);

    if (res.ok) {
      const blob = await res.blob();
      downloadFile(blob, "sample.xlsx");
    } else {
      throw new Error("Something went wrong while downloading the sample file");
    }
  } catch (error) {
    console.error("Error", error);
    throw error;
  }
};

export const exportContacts = (param) => {
  const url = param
    ? `whatsapp-business/contacts/export/?${param}`
    : "whatsapp-business/contacts/export/";

  return apiFetch("GET", url, null, true)
    .then((res) => {
      if (res.ok) {
        return res.blob();
      } else {
        throw new Error("Something went wrong with Export contacts");
      }
    })
    .then((blob) => {
      downloadFile(blob, "pinaippu_contacts.xlsx");
    })
    .catch((error) => {
      console.error("Error", error);
      throw error;
    });
};
