import React from "react";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
const ErrorPage = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <div>
        <p style={{ display: "flex", alignItems: "center", fontSize: "30px" }}>
          <ErrorOutlineIcon
            style={{
              color: "#ff991f",
              marginRight: "10px",
              width: "100px",
              height: "100px",
            }}
          />
          Page Not Found
        </p>
      </div>
    </div>
  );
};

export default ErrorPage;
