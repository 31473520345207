import React, { useState, useEffect, useRef, useCallback } from "react";
import "../Contacts/Contacts.css";
import {
  Button,
  TextField,
  InputLabel,
  Grid,
  Box,
  Menu,
  Select,
  MenuItem,
  Autocomplete,
  IconButton,
  MenuList,
  Avatar,
  FormControlLabel,
  Checkbox,
  Dialog,
  DialogTitle,
  DialogContent,
  useMediaQuery,
  Stack,
} from "@mui/material";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import InputAdornment from "@mui/material/InputAdornment";
// import { apiFetch } from "../../API/CommonApi";
import Modal from "@material-ui/core/Modal";
import {
  Controller,
  FormProvider,
  useForm,
  useFieldArray,
} from "react-hook-form";
import { notify, parseFormattedText, isMobile } from "../Common";
import { debounce, max } from "lodash";
// import CampaignIcon from "@mui/icons-material/Campaign";
// import NotificationsIcon from "@mui/icons-material/Notifications";
// import KeyTwoToneIcon from "@mui/icons-material/KeyTwoTone";
import { ReactComponent as Spinner_2 } from "../../Assets/Spinner_2.svg";
import { btnCss, TextfieldCss, ClsBtn } from "../../Styles";
import CloseIcon from "@mui/icons-material/Close";
import { createTemplateReview as apiCreateTemplateReview } from "../../API/TemplateLayer";
import { templateStatus as apiTemplateStatus } from "../../API/TemplateLayer";
import { createTemplateDraft as apiCreateTemplateDraft } from "../../API/TemplateLayer";
import { templateEditDraft as apiEditTemplateDraft } from "../../API/TemplateLayer";
import { editTemplateReview as apiEditTemplateReview } from "../../API/TemplateLayer";
import { templatePreview as apiTemplatePreview } from "../../API/TemplateLayer";
import { getTemplateTags as apiGetTemplateTags } from "../../API/TemplateLayer";
import InsertEmoticonIcon from "@mui/icons-material/InsertEmoticon";
import EmojiPicker from "emoji-picker-react";
import { customButtonItems } from "../../API/TemplateLayer";
// import RemoveIcon from "@mui/icons-material/Remove";
// import AddIcon from "@mui/icons-material/Add";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import PhoneInput from "react-phone-input-2";
import CallIcon from "@mui/icons-material/Call";
import LaunchIcon from "@mui/icons-material/Launch";
// const addtempcss = {
//   width: "100px",
//   height: "100px",
//   margin: "0 auto",
//   overflow: "auto",
// };

// instructions
const instructionCss = {
  backgroundColor: "#efefef",
  margin: "0 10px 10px 10px",
  borderRadius: "4px",
  color: "#14181b",
  padding: "5px",
  fontSize: "10px",
};
const BtnCSS = {
  maxWidth: "412px",
  // marginTop: "15px",
  margin: "15px 15px 0 0",
  background: "linear-gradient(272.65deg, #383194 0%, #da1e7a 100%)",
  borderRadius: " 5px",
};
const custBtnCtrCss = {
  color: "#303535",
  // margin: "10px 0 10px 0",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  padding: "0 6px 0 0",
};
const noteCss = {
  backgroundColor: "#efefef",
  margin: "10px 10px 10px 10px",
  borderRadius: "4px",
  color: "#14181b",
  padding: "5px",
  fontSize: "10px",
};

export const AddTemplate = ({
  // WpDetails,
  loadTemplates,
  editData,
  openNewTemplate,
  setOpenNewTemplate,
  setEditData,
}) => {
  const [edit, setedit] = useState(false);
  const [previewLoading, setPreviewLoading] = useState(false);
  const [preview, setPreview] = useState(null);
  const [emojiField, setEmojiField] = useState(null);
  const mobile = isMobile();
  const is1168px = useMediaQuery("(max-width:1168px)");
  const is900px = useMediaQuery("(max-width:900px)");
  const [typAct, setTypACT] = useState([
    {
      name: "",
      value: "",
      id: null,
    },
    {
      name: "",
      value: "",
      id: null,
    },
  ]);
  const methods = useForm({
    mode: "onBlur",
    defaultValues: {
      call_to_action: [""],
      category: "",
      buttons: "",
      quickReply: [""],
    },
  });

  const { handleSubmit, reset, control, formState, setValue, watch } = methods;
  const { errors } = formState;
  const watchAllFields = watch({});

  const {
    fields: call_to_action,
    append: appendCallToAction,
    remove: removeCallToAction,
  } = useFieldArray({
    control,
    name: "call_to_action",
  });

  const {
    fields: quickReply,
    append: appendQuickReply,
    remove: removeQuickReply,
  } = useFieldArray({
    control,
    name: "quickReply",
  });
  const [preData, setPreData] = useState(null);
  const [ApiLoading, SetApiLoading] = useState(false);
  const [media, setMedia] = useState(null);
  const [mediaName, setMediaName] = useState(null);

  const [showHdrContentMenu, setHdrContentMenu] = useState(false);
  const [showBdyContentMenu, setBdyContentMenu] = useState(false);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const inputRef = useRef(null);
  const textFieldRef = useRef(null);
  const textFieldRef2 = useRef(null);
  const shouldRunFunctions = window.location.pathname === "/templateMessage";
  const [template_tags, setTemplate_tags] = useState(null);
  const categoryOptions = [
    {
      name: "Marketing",
      value: "marketing",
      id: 0,
    },
    {
      name: "Utility",
      value: "utility",
      id: 1,
    },
    {
      name: "Authentication",
      value: "authentication",
      id: 2,
    },
  ];
  const [btnOptions, setBtnOptions] = useState([
    {
      name: "None",
      value: "none",
      id: 0,
    },
    {
      name: "Call to Action",
      value: "call_to_action",
      id: 1,
    },
    {
      name: "Quick Reply",
      value: "quick_reply",
      id: 2,
    },
  ]);

  const typActOptions = [
    {
      name: "Call Phone Number",
      value: "PHONE_NUMBER",
      id: 0,
    },
    {
      name: "Visit Web Site",
      value: "URL",
      id: 1,
    },
  ];
  const urlTypeOptions = [
    {
      name: "Static",
      value: "static",
      id: 0,
    },
    {
      name: "Dynamic",
      value: "dynamic",
      id: 1,
    },
  ];
  const MAX_IMAGE_SIZE_BYTES = 5 * 1024 * 1024; // 5 MB in bytes
  const MAX_VIDEO_SIZE_BYTES = 16 * 1024 * 1024; // 16 MB in bytes
  const MAX_DOCUMENT_SIZE_BYTES = 100 * 1024 * 1024; // 100 MB in bytes

  const SIZE_MAPPINGS = {
    image: MAX_IMAGE_SIZE_BYTES,
    video: MAX_VIDEO_SIZE_BYTES,
    document: MAX_DOCUMENT_SIZE_BYTES,
  };

  const handleClose = () => {
    setOpenNewTemplate(false);
    setPreData(null);
    setPreview(null);
    if (editData) {
      setEditData(null);
    }
    setSelectedMedia("image");
    setMedia(null);
    setMediaName(null);
    setSelectedOption(headerOptions[0]);
    reset();
  };
  // const [isCatalogue, setIsCatalogue] = useState(false);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const maxSize = SIZE_MAPPINGS[selectedMedia];
      if (file.size <= maxSize) {
        const reader = new FileReader();
        reader.onload = (e) => {
          setMedia(e.target.result);
          setMediaName(file.name);
        };
        reader.readAsDataURL(file);
      } else {
        notify(
          "info",
          `The selected file exceeds the maximum allowed size of ${
            maxSize / (1024 * 1024)
          } MB.`
        );
        setMedia(null);
        setMediaName(null);
        setSelectedMedia("image");
      }
    } else {
      setMedia(null);
      setMediaName(null);
    }
  };

  const toggleEmojiPicker = (fieldName) => {
    setEmojiField(fieldName);
    setShowEmojiPicker(!showEmojiPicker);
  };

  const handleEmojiSelect = (emoji) => {
    const currentHeaderContent = watchAllFields.header_content_text || "";
    const currentBodyContent = watchAllFields.body_content || "";

    if (emojiField === "header_content_text") {
      const updatedHeaderContent = `${currentHeaderContent}${
        emoji?.emoji || ""
      }`;
      setValue("header_content_text", updatedHeaderContent);
      setPreview({
        ...preview,
        header: updatedHeaderContent,
      });
    } else if (emojiField === "body_content") {
      const updatedBodyContent = `${currentBodyContent}${emoji?.emoji || ""}`;
      setValue("body_content", updatedBodyContent);

      setPreview({
        ...preview,
        body: updatedBodyContent,
      });
    }
  };

  useEffect(() => {
    if (showEmojiPicker && inputRef.current) {
      setTimeout(() => {
        inputRef.current.focus();
      }, 0);
    }
  }, [showEmojiPicker]);

  // on selecting Suggetions it will fill the field
  const handleSlecetSuggetion = (field, option) => {
    if (field === "header_content_text") {
      const pre = watchAllFields.header_content_text.replace(/{{[^{]*$/, "");
      setValue("header_content_text", `${pre}{{${option}}} `);
      setHdrContentMenu(false);
      textFieldRef.current.focus();
    } else if (field === "body_content") {
      const pre = watchAllFields.body_content.replace(/{{[^{]*$/, "");
      setValue("body_content", `${pre}{{${option}}} `);
      setBdyContentMenu(false);
      textFieldRef2.current.focus();
    }
  };

  const debouncedSearch = debounce((temp_Key) => {
    const Key = temp_Key.target.value;
    if (Key) {
      const lastString = Key?.slice(Key.lastIndexOf("{{") + 2);
      lastString && getTemplateTags(lastString);
    }
  }, 600);

  const getTemplateTags = (param) => {
    apiGetTemplateTags(param)
      .then((res) => {
        setTemplate_tags(res.template_tags);
      })
      .catch((error) => {
        console.error("Error", error);
        notify("error", "Something went wrong with template tags");
      });
  };
  // create and send to review
  const createTemplateReview = (data) => {
    SetApiLoading(true);
    const filteredData = Object.fromEntries(
      Object.entries(data).filter(([key, value]) => value !== undefined)
    );

    const mergedData = { ...filteredData };

    const formData = new FormData();

    var buttonData = {};
    for (let key in mergedData) {
      if (data.hasOwnProperty(key)) {
        const value = mergedData[key];

        if (key === "category") {
          formData.append("category", value.value);
        } else if (key === "buttons") {
          buttonData.buttons = mergedData.buttons;
        } else if (key === "call_to_action") {
          buttonData.call_to_action = mergedData.call_to_action;
        } else if (key === "quickReply") {
          buttonData.quickReply = mergedData.quickReply;
        } else if (
          key === "header_content_text" &&
          mergedData["header_content_type"] === "media"
        ) {
          continue;
        } else {
          formData.append(key, value);
        }
      }
    }
    data = Object.fromEntries(formData);

    apiCreateTemplateReview(data, selectedMedia, buttonData)
      .then((res) => {
        loadTemplates();
        handleClose();
        SetApiLoading(false);
        notify("success", "Template Saved");
      })
      .catch((error) => {
        console.error("Error", error);
        notify("error", "Something went wrong");
        SetApiLoading(false);
      })
      .finally(() => {
        reset();
      });

    //reset();
  };

  // create and send to Draft
  const createTemplateDraft = (data) => {
    SetApiLoading(true);
    const filteredData = Object.fromEntries(
      Object.entries(data).filter(([key, value]) => value !== undefined)
    );
    const mergedData = { ...filteredData };
    const formData = new FormData();
    var buttonData = {};
    for (let key in mergedData) {
      if (data.hasOwnProperty(key)) {
        const value = mergedData[key];
        if (key === "category") {
          formData.append("category", value.value);
        } else if (key === "buttons") {
          buttonData.buttons = mergedData.buttons;
        } else if (key === "call_to_action") {
          buttonData.call_to_action = mergedData.call_to_action;
        } else if (key === "quickReply") {
          buttonData.quickReply = mergedData.quickReply;
        } else if (
          key === "header_content_text" &&
          mergedData["header_content_type"] === "media"
        ) {
          continue;
        } else {
          formData.append(key, value);
        }
      }
    }
    data = Object.fromEntries(formData);
    apiCreateTemplateDraft(data, selectedMedia, buttonData)
      .then((res) => {
        shouldRunFunctions && loadTemplates();
        handleClose && handleClose();
        SetApiLoading(false);
        notify("success", "Template Saved");
      })
      .catch((error) => {
        console.error("Error", error);
        SetApiLoading(false);
        notify("error", "Something went wrong");
      })
      .finally(() => {
        reset();
      });
  };

  // Edit Template send to draft
  const TemplateEditDraft = (data) => {
    SetApiLoading(true);
    const filteredData = Object.fromEntries(
      Object.entries(data).filter(
        ([key, value]) => value !== undefined && value !== null
      )
    );

    const mergedData = { ...filteredData };
    const formData = new FormData();

    var buttonData = {};
    for (let key in mergedData) {
      if (data.hasOwnProperty(key)) {
        const value = mergedData[key];
        if (key === "category") {
          formData.append("category", value.value);
        } else if (key === "buttons") {
          buttonData.buttons = mergedData.buttons;
        } else if (key === "call_to_action") {
          buttonData.call_to_action = mergedData.call_to_action;
        } else if (key === "quickReply") {
          buttonData.quickReply = mergedData.quickReply;
        } else if (key === "header_example" || key === "body_example") {
          // Ensure that the value is an array before appending to formData
          if (Array.isArray(value)) {
            value.forEach((item, index) => {
              // console.log("key", key, "item", item);
              formData.append(`${key}.[${index}]`, item);
            });
          } else {
            const tempArr = value?.split(",");

            tempArr?.forEach((item, index) => {
              // console.log("key", key, "item", item);
              formData.append(`${key}.[${index}]`, item);
            });
          }
        } else {
          formData.append(key, value);
        }
      }
    }
    data = Object.fromEntries(formData);
    apiEditTemplateDraft(editData.id, data, selectedMedia, buttonData)
      .then((res) => {
        loadTemplates();
        handleClose();
        edit && setedit(null);
        notify("success", "Template updated");
        SetApiLoading(false);
      })
      .catch((error) => {
        console.error("Error", error);
        SetApiLoading(false);
        notify("error", "Something went wrong");
      })
      .finally(() => {
        reset();
      });
  };

  // Edit template Send to Review
  const TemplateEditReview = (data) => {
    SetApiLoading(true);
    const filteredData = Object.fromEntries(
      Object.entries(data).filter(([key, value]) => value !== undefined)
    );
    const mergedData = { ...filteredData };
    const formData = new FormData();
    var buttonData = {};
    for (let key in mergedData) {
      if (data.hasOwnProperty(key)) {
        const value = mergedData[key];
        if (key === "category") {
          formData.append("category", value.value);
        } else if (key === "buttons") {
          buttonData.buttons = mergedData.buttons;
        } else if (key === "call_to_action") {
          buttonData.call_to_action = mergedData.call_to_action;
        } else if (key === "quickReply") {
          buttonData.quickReply = mergedData.quickReply;
        } else if (key === "header_example" || key === "body_example") {
          // Ensure that the value is an array before appending to formData
          if (Array.isArray(value)) {
            value.forEach((item, index) => {
              // console.log("key", key, "item", item);
              formData.append(`${key}.[${index}]`, item);
            });
          } else {
            const tempArr = value?.split(",");

            tempArr?.forEach((item, index) => {
              // console.log("key", key, "item", item);
              formData.append(`${key}.[${index}]`, item);
            });
          }
        } else {
          formData.append(key, value);
        }
      }
    }
    data = Object.fromEntries(formData);

    apiEditTemplateReview(editData.id, data, selectedMedia, buttonData)
      .then((res) => {
        loadTemplates();
        handleClose();
        edit && setedit(null);
        SetApiLoading(false);
        notify("success", "Template updated");
      })
      .catch((error) => {
        console.error("Error", error);
        SetApiLoading(false);
        notify("error", "Something went wrong");
      })
      .finally(() => {
        reset();
      });
  };
  const templatePreview = (body) => {
    if (body.body_content) {
      apiTemplatePreview(body).then((res) => {
        setPreview(res);
        setPreviewLoading(false);
      });
    }
  };
  const handlePreview = debounce(() => {
    if (watchAllFields.body_content) {
      const data = watchAllFields;
      const bodyExample =
        typeof data.body_example === "string"
          ? data?.body_example?.split(",")
          : data?.body_example;
      const headreExample =
        typeof data.header_example === "string"
          ? data?.header_example?.split(",")
          : data?.header_example;

      const customButtons = customButtonItems(
        data.call_to_action,
        data.quickReply,
        data.buttons
      );

      const body = {
        body_content: data.body_content,
        body_example: bodyExample,
        footer_content: data?.footer_content,
        header_content_text: data?.header_content_text,
        header_example: headreExample,
        buttons: customButtons,
      };
      setPreviewLoading(true);
      var intervalID = setInterval(() => {
        templatePreview(body);
      }, 1000);
      setTimeout(() => {
        clearInterval(intervalID);
      }, 1500);
    }
  }, 500);

  useEffect(() => {
    if (
      typeof watchAllFields.header_content_text === "string" &&
      watchAllFields.header_content_text.endsWith("{{")
    ) {
      getTemplateTags(" ");
      setHdrContentMenu(true);
    }
    if (
      typeof watchAllFields.header_content_text === "string" &&
      watchAllFields.header_content_text.endsWith("}}")
    ) {
      setHdrContentMenu(false);
    }

    if (
      typeof watchAllFields.body_content === "string" &&
      watchAllFields.body_content.endsWith("{{")
    ) {
      getTemplateTags(" ");
      setBdyContentMenu(true);
    }
    if (
      typeof watchAllFields.body_content === "string" &&
      watchAllFields.body_content.endsWith("}}")
    ) {
      setBdyContentMenu(false);
    }
  }, [watchAllFields.header_content_text, watchAllFields.body_content]);

  const ifCallTOAction = (data) => {
    const buttons = typeof data === "string" ? JSON.parse(data) : data;
    if (
      buttons?.some((obj) => obj.type === "URL" || obj.type === "PHONE_NUMBER")
    ) {
      return (
        <div style={{ borderTop: "1px solid #dadde1" }}>
          {buttons?.map((item, index) => (
            <p
              key={index}
              style={{
                color: "#33b7f6",
                textAlign: "center",
                margin: "5px 0 0 0",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
                gap: "10px",
                fontWeight: "normal",
              }}
            >
              {item.type == "PHONE_NUMBER" ? (
                <CallIcon
                  style={{
                    width: "20px",
                    marginRight: "-5px",
                  }}
                />
              ) : (
                <LaunchIcon
                  style={{
                    width: "20px",
                    marginRight: "-5px",
                  }}
                />
              )}
              {item.text}
            </p>
          ))}
        </div>
      );
    }
  };
  const ifQuickreply = (data) => {
    const buttons = typeof data === "string" ? JSON.parse(data) : data;
    if (
      buttons?.some((obj) => obj.type !== "URL" && obj.type !== "PHONE_NUMBER")
    ) {
      return (
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            maxWidth: "295px",
            justifyContent: "center",
          }}
        >
          {buttons?.map((item, index) => (
            <p
              key={index}
              style={{
                color: "#33b7f6",
                textAlign: "center",
                margin: "2px",
                background: "#ffffff",
                minWidth: "100px",
                cursor: "pointer",
                padding: "8px",
                borderRadius: "8px",
                width: "100%",
                maxWidth: "300px",
                flexGrow: "2",
              }}
            >
              {item.text}
            </p>
          ))}
        </div>
      );
    }
  };

  useEffect(() => {
    // console.log("watchAllFields", watchAllFields);
    if (watchAllFields.template_type?.value === "catalogue") {
      setValue("buttons.0.type", "Catalog");
      setValue("buttons.0.text", "View catalog");
    }
  }, [watchAllFields.template_type]);
  // useEffect(() => {
  //   console.log("watchAllFields", watchAllFields);
  // }, [watchAllFields]);
  useEffect(() => {
    if (editData) {
      const preCategory = categoryOptions.find(
        (p) => p.value === editData.category
      );
      setPreviewLoading(true);
      // console.log(editData);
      apiTemplatePreview({ template_name: editData?.name })
        .then((res) => {
          setPreview(res);
          setPreviewLoading(false);
        })
        .catch((error) => {
          console.error("Error", error);
          notify("error", "Something went wrong with Preview");
        });

      if (editData.header_content_image !== null) {
        setSelectedMedia("image");
        setMedia(editData.header_content_image);
        setMediaName(
          editData.header_content_image.substring(
            editData.header_content_image.lastIndexOf("/") + 1,
            editData.header_content_image.lastIndexOf("?")
          )
        );
      } else if (editData.header_content_document !== null) {
        setSelectedMedia("document");
        setMedia(editData.header_content_document);
        setMediaName(
          editData.header_content_document.substring(
            editData.header_content_document.lastIndexOf("/") + 1,
            editData.header_content_document.lastIndexOf("?")
          )
        );
      } else if (editData.header_content_video !== null) {
        setSelectedMedia("video");
        setMedia(editData.header_content_video);
        setMediaName(
          editData.header_content_video.substring(
            editData.header_content_video.lastIndexOf("/") + 1,
            editData.header_content_video.lastIndexOf("?")
          )
        );
      } else {
        //setSelectedMedia("location");
      }

      // console.log("selectedOption", selectedOption);
      setedit(true);
      setValue("name", editData.name);
      setValue("body_content", editData.body_content);
      setValue("footer_content", editData.footer_content);
      setValue("header_example", editData.header_example);
      setValue("body_example", editData.body_example);
      setValue("category", preCategory);
      //setMedia(editData.header_content_image);
      if (editData.header_content_type === "text") {
        setValue("header_content_text", editData.header_content_text);
      }
      if (
        editData.buttons &&
        editData.buttons[0] &&
        editData.buttons[0].type === "Catalogue"
      ) {
        setValue("template_type", {
          name: "catalogue",
          value: "catalogue",
          id: 1,
        });
        setValue("thumbnail_image_id", editData.thumbnail_image_id);
        setValue("header_content_text", editData.header_content_text);
        setSelectedOption(headerOptions[0]);
      } else {
        const foundOption = headerOptions.find(
          (option) => option.value === editData.header_content_type
        );
        setSelectedOption(foundOption);
      }
      const tempButtons =
        typeof editData?.buttons === "string"
          ? JSON.parse(editData?.buttons)
          : editData?.buttons;
      if (
        tempButtons !== null &&
        tempButtons.some(
          (obj) => obj.type === "URL" || obj.type === "PHONE_NUMBER"
        )
      ) {
        setValue("buttons", btnOptions[1]);
        let newData = [...typAct];

        tempButtons?.forEach((data, index) => {
          if (data.type === "PHONE_NUMBER") {
            appendCallToAction({
              buttonText: data.text,
              phone_number: data.phone_number,
            });
            setValue(`call_to_action.${index}.typeOfAction`, typActOptions[0]);
            // setValue(`call_to_action.${index}.phone_number`, data.phone_number);
            newData[index] = typActOptions[0];
            setTypACT(newData);
          } else if (data.type === "URL") {
            appendCallToAction({ buttonText: data.text, url: data.url });
            setValue(`call_to_action.${index}.typeOfAction`, typActOptions[1]);
            newData[index] = typActOptions[1];
            setTypACT(newData);
            // setValue(`call_to_action.${index}.url`, data.URL);
          }
        });
      } else if (tempButtons?.some((obj) => obj.type === "QUICK_REPLY")) {
        setValue("buttons", btnOptions[2]);
        tempButtons?.forEach((data, index) => {
          appendQuickReply({ buttonText: data.text });
        });
      }
    } else {
      reset();
      setedit(false);
      setValue("buttons", btnOptions[0]);
    }
  }, [openNewTemplate]);

  const [fieldFocus, setFieldFocus] = useState({
    name: false,
    header_content_text: false,
    header_example: false,
    body_content: false,
    body_example: false,
    footer_content: false,
    category: false,
    buttons: false,
  });
  const headerOptions = [
    {
      name: "None",
      value: "none",
      id: 0,
    },
    {
      name: "Text",
      value: "text",
      id: 1,
    },
    {
      name: "Media",
      value: "media",
      id: 2,
    },
  ];
  const [selectedOption, setSelectedOption] = useState(headerOptions[0]);
  const [selectedMedia, setSelectedMedia] = useState("image");

  // const handleOptionChange = (e) => {
  //   const newValue = e.target.value;
  //   // if (newValue !== selectedOption) {
  //   //   setSelectedOption(newValue);
  //   // }
  // };
  const handleMediaChange = (e) => {
    setMedia(null);
    setMediaName(null);
    setSelectedMedia(e.target.value);
  };

  const handleFocus = useCallback((field) => {
    setFieldFocus({
      ...fieldFocus,
      [field]: true,
    });
  }, []);

  const handleBlur = useCallback((field) => {
    setFieldFocus({
      ...fieldFocus,
      [field]: false,
    });
  }, []);

  const onSubmit = (data) => {
    const buttonType = window.event.submitter.name;
    let updatedData = data;

    if (mediaName && media) {
      const isURL = /^https?:\/\/\S+$/i.test(media);
      if (!isURL) {
        updatedData = {
          ...updatedData,
          file: [media],
          fileName: mediaName,
          header_content_type: selectedOption.value,
        };
      }
    }

    if (editData) {
      // console.log("Edit");
      if (buttonType === "send_to_draft") {
        // console.log("edit as draft");
        TemplateEditDraft(updatedData);
      } else {
        // console.log("Send To review from edit");
        TemplateEditReview(updatedData);
      }
    } else {
      // console.log("Save");
      if (buttonType === "send_to_draft") {
        // console.log("save as draft");

        createTemplateDraft(updatedData);
      } else {
        // console.log("Send To review");
        createTemplateReview(updatedData);
      }
    }
  };

  return (
    <div>
      <Dialog
        open={openNewTemplate}
        onClose={handleClose}
        fullScreen={mobile}
        maxWidth={mobile ? false : "md"}
        PaperProps={{
          style: {
            width: is1168px ? "100%" : "65%",
            height: is1168px ? "100%" : "90%",
            overflow: "auto",
          },
        }}
      >
        <DialogTitle
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          {edit ? "Update Template" : "Add Template"}
          <CloseIcon onClick={handleClose} style={{ cursor: "pointer" }} />
        </DialogTitle>

        <DialogContent dividers>
          <Stack flexDirection={"row"} gap={2}>
            <div
              style={{ maxWidth: "400px", width: "100%", minWidth: "300px" }}
            >
              <FormProvider {...methods} enctype="multipart/form-data">
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div>
                    <InputLabel sx={{ color: "#303535", margin: "5px 0" }}>
                      Category *
                    </InputLabel>
                    <Controller
                      render={({ field: { value, onChange } }) => (
                        <Autocomplete
                          style={{ maxWidth: "390px" }}
                          value={value}
                          // defaultValue={null}
                          options={categoryOptions}
                          getOptionLabel={(option) =>
                            option ? option.name : ""
                          }
                          onChange={(e, data) => onChange(data)}
                          isOptionEqualToValue={(option, value) =>
                            option.id === value.id
                          }
                          renderInput={(params) => (
                            <TextField
                              onKeyUp={handlePreview}
                              {...params}
                              size="small"
                              error={Boolean(errors.category)}
                              onFocus={() => handleFocus("category")}
                              onBlur={() => {
                                // handlePreview();
                                handleBlur("category");
                              }}
                              helperText={
                                errors.category && "Category is required"
                              }
                            />
                          )}
                        />
                      )}
                      rules={{ required: true }}
                      name="category"
                      control={control}
                    />
                    {fieldFocus.category && (
                      <div
                        style={{
                          backgroundColor: "#efefef",
                          margin: "10px 10px 10px 10px",
                          borderRadius: "4px",
                          color: "#14181b",
                          padding: "5px",
                          fontSize: "10px",
                        }}
                      >
                        <p style={{ marginBottom: "5px" }}>
                          Choose a category that best describes your message
                          template.
                        </p>
                        <div
                          style={{
                            marginBottom: "5px",
                          }}
                        >
                          <div>
                            <p>
                              <strong>Marketing</strong>
                            </p>
                            <p>
                              Send promotions or information about your
                              products, services or business.
                            </p>
                          </div>
                        </div>
                        <div
                          style={{
                            marginBottom: "5px",
                          }}
                        >
                          <div>
                            <p>
                              <strong>Utility</strong>
                            </p>
                            <p>
                              Send messages about an existing order or account.
                            </p>
                          </div>
                        </div>
                        <div
                          style={{
                            marginBottom: "5px",
                          }}
                        >
                          <div>
                            <p>
                              <strong> Authentication</strong>
                            </p>
                            <p>Send codes to verify a transaction or login.</p>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                  {watchAllFields.category?.value === "marketing" && (
                    <div>
                      <InputLabel sx={{ color: "#303535", margin: "5px 0" }}>
                        Template Type *
                      </InputLabel>
                      <Controller
                        render={({ field: { value, onChange } }) => (
                          <Autocomplete
                            style={{ maxWidth: "390px" }}
                            value={value}
                            defaultValue={{
                              name: "Custom",
                              value: "custom",
                              id: 0,
                            }}
                            options={[
                              {
                                name: "Custom",
                                value: "custom",
                                id: 0,
                              },
                              {
                                name: "catalogue",
                                value: "catalogue",
                                id: 1,
                              },
                            ]}
                            getOptionLabel={(option) =>
                              option ? option.name : ""
                            }
                            onChange={(e, data) => onChange(data)}
                            isOptionEqualToValue={(option, value) =>
                              option.id === value.id
                            }
                            renderInput={(params) => (
                              <TextField
                                // onKeyUp={handlePreview}
                                {...params}
                                size="small"
                                error={Boolean(errors.template_type)}
                                // onFocus={() => handleFocus("template_type")}
                                // onBlur={() => {
                                //   // handlePreview();
                                //   handleBlur("template_type");
                                // }}
                                helperText={
                                  errors.template_type &&
                                  "Template type is required"
                                }
                              />
                            )}
                          />
                        )}
                        // rules={{ required: true }}
                        name="template_type"
                        control={control}
                      />
                    </div>
                  )}
                  <div>
                    <InputLabel sx={{ color: "#303535", margin: "5px 0" }}>
                      Name *
                    </InputLabel>
                    <Controller
                      render={({ field }) => (
                        <TextField
                          onKeyUp={handlePreview}
                          {...field}
                          size="small"
                          fullWidth
                          onFocus={() => handleFocus("name")}
                          onBlur={() => handleBlur("name")}
                          sx={{
                            maxWidth: "388.58px",
                            width: "100%",
                            marginBottom: "10px",
                          }}
                          error={Boolean(errors?.name)}
                          helperText={
                            errors?.name?.type === "required"
                              ? "Name is required"
                              : errors?.name?.type === "pattern"
                              ? "Name can only have lowercase / numbers / underscore."
                              : ""
                          }
                        />
                      )}
                      rules={{ required: true, pattern: /^[a-z0-9_]+$/ }}
                      name="name"
                      control={control}
                    />
                    {fieldFocus.name && (
                      <div style={instructionCss}>
                        <p style={{ marginBottom: "5px" }}>
                          Name your message template.
                        </p>
                        <p>
                          {" "}
                          Name can only have lowercase / numbers / underscore.
                        </p>
                      </div>
                    )}
                  </div>
                  <div>
                    <InputLabel sx={{ color: "#303535", margin: "5px 0" }}>
                      Header Content
                    </InputLabel>
                    <Autocomplete
                      readOnly={
                        watchAllFields.template_type?.value === "catalogue"
                          ? true
                          : false
                      }
                      style={{ maxWidth: "390px" }}
                      value={selectedOption}
                      defaultValue={headerOptions[0]}
                      options={headerOptions}
                      getOptionLabel={(option) => (option ? option.name : "")}
                      onChange={(e, data) => {
                        setSelectedOption(data);
                        setMedia(null);
                        setMediaName(null);
                      }}
                      disableClearable
                      isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                      }
                      renderInput={(params) => (
                        <TextField {...params} size="small" />
                      )}
                    />
                    {selectedOption?.value === "text" && (
                      <>
                        <Controller
                          name="header_content_text"
                          control={control}
                          render={({ field: { value, onChange } }) => (
                            <TextField
                              onKeyUp={handlePreview}
                              inputProps={{ ref: textFieldRef, maxLength: 60 }}
                              value={value}
                              onFocus={() => handleFocus("header_content_text")}
                              onBlur={() => {
                                handleBlur("header_content_text");
                              }}
                              onChange={(x) => {
                                onChange(x);
                                debouncedSearch(x);
                              }}
                              size="small"
                              fullWidth
                              sx={{
                                maxWidth: "388.58px",
                                width: "100%",
                                marginBottom: "10px",
                                marginTop: "10px",
                              }}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment
                                    position="end"
                                    style={{
                                      position: "absolute",
                                      bottom: "20px",
                                      right: "0",
                                    }}
                                  >
                                    <div
                                      className={`rotate-button`}
                                      onClick={() =>
                                        toggleEmojiPicker("header_content_text")
                                      }
                                      style={{
                                        cursor: "pointer",
                                        transform: "scale(0.8)",
                                      }}
                                    >
                                      <InsertEmoticonIcon />
                                    </div>
                                  </InputAdornment>
                                ),
                              }}
                            />
                          )}
                        />
                        <div
                          style={{
                            fontSize: "14px",
                            color: "gray",
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "flex-end",
                            flexDirection: "column",
                          }}
                        >
                          {watchAllFields.header_content_text
                            ? `${watchAllFields.header_content_text.length}/60`
                            : "0/60"}
                        </div>
                      </>
                    )}
                    {selectedOption?.value === "media" && (
                      <Stack>
                        <InputLabel sx={{ color: "#303535", margin: "5px 0" }}>
                          Select a media Type
                        </InputLabel>
                        <Stack
                          flexDirection={"row"}
                          alignItems={"center"}
                          justifyContent={"space-around"}
                          gap={1}
                        >
                          <Select
                            value={selectedMedia}
                            onChange={handleMediaChange}
                            size="small"
                          >
                            <MenuItem value="image">Image</MenuItem>
                            <MenuItem value="document">Document</MenuItem>
                            <MenuItem value="video">Video</MenuItem>
                            {/* <MenuItem value="location">Location</MenuItem> */}
                          </Select>
                          {selectedMedia !== "none" && (
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <input
                                accept={
                                  selectedMedia === "image"
                                    ? "image/jpeg,image/jpg,image/png"
                                    : selectedMedia === "document"
                                    ? ".pdf"
                                    : selectedMedia === "video"
                                    ? "video/mp4"
                                    : ""
                                }
                                type="file"
                                id="upload-media"
                                style={{ display: "none" }}
                                onChange={handleFileChange}
                                name="file"
                              />
                              <div style={{ float: "right" }}>
                                <label htmlFor="upload-media">
                                  <Button variant="contained" component="span">
                                    Upload {selectedMedia}
                                  </Button>
                                </label>
                                <p
                                  style={{
                                    maxWidth: "180px",
                                    wordBreak: "break-all",
                                    overflowWrap: "break-word",
                                  }}
                                >
                                  {mediaName}
                                </p>
                              </div>
                            </div>
                          )}
                        </Stack>
                      </Stack>
                    )}
                    {showHdrContentMenu &&
                      watchAllFields.header_content_text && (
                        <div className="suggetionsCtr">
                          <MenuList>
                            {template_tags?.length > 0 ? (
                              template_tags.map((tag, index) => (
                                <MenuItem
                                  key={index}
                                  onClick={() =>
                                    handleSlecetSuggetion(
                                      "header_content_text",
                                      tag
                                    )
                                  }
                                >
                                  {tag}
                                </MenuItem>
                              ))
                            ) : (
                              <p style={{ textAlign: "center" }}>Loadding...</p>
                            )}
                          </MenuList>
                        </div>
                      )}
                    {fieldFocus.header_content_text && (
                      <div style={instructionCss}>
                        <p style={{ marginBottom: "5px" }}>
                          Add a title to your message .
                        </p>
                        <p>
                          To add variables to Title ,use placeholders like
                          &#123;&#123;1&#125;&#125;,&#123;&#123;2&#125;&#125;etc.
                        </p>
                      </div>
                    )}
                  </div>
                  {selectedOption?.value === "text" && (
                    <div>
                      <InputLabel sx={{ color: "#303535", margin: "5px 0" }}>
                        Header Example
                      </InputLabel>
                      <>
                        <Controller
                          render={({ field }) => (
                            <TextField
                              onKeyUp={handlePreview}
                              onFocus={() => handleFocus("header_example")}
                              onBlur={() => {
                                // handlePreview();
                                handleBlur("header_example");
                              }}
                              {...field}
                              size="small"
                              fullWidth
                              inputProps={{ maxLength: 100 }}
                              sx={{
                                maxWidth: "388.58px",
                                width: "100%",
                                marginBottom: "10px",
                              }}
                            />
                          )}
                          name="header_example"
                          control={control}
                        />
                        <div
                          style={{
                            fontSize: "14px",
                            color: "gray",
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "flex-end",
                            flexDirection: "column",
                          }}
                        >
                          {watchAllFields.header_example
                            ? `${watchAllFields.header_example.length}/100`
                            : "0/100"}
                        </div>
                      </>
                      {fieldFocus.header_example && (
                        <div style={instructionCss}>
                          <p style={{ marginBottom: "5px" }}>
                            Add variables to Title.
                          </p>
                          <p style={{ marginBottom: "5px" }}>
                            Separate Variables with "," (comma) .
                          </p>
                        </div>
                      )}
                    </div>
                  )}
                  {watchAllFields.template_type?.value === "catalogue" && (
                    <div>
                      <InputLabel sx={{ color: "#303535", margin: "5px 0" }}>
                        Thumbnail iD
                      </InputLabel>
                      <Controller
                        render={({ field }) => (
                          <TextField
                            // onKeyUp={handlePreview}
                            {...field}
                            size="small"
                            fullWidth
                            // onFocus={() => handleFocus("name")}
                            // onBlur={() => handleBlur("name")}
                            sx={{
                              maxWidth: "388.58px",
                              width: "100%",
                              marginBottom: "10px",
                            }}
                            error={Boolean(errors?.thumbnail_image_id)}
                            // helperText={
                            //   errors?.name?.type === "required"
                            //     ? "Name is required"
                            //     : errors?.name?.type === "pattern"
                            //     ? "Name can only have lowercase / numbers / underscore."
                            //     : ""
                            // }
                          />
                        )}
                        // rules={{ required: true, pattern: /^[a-z0-9_]+$/ }}
                        name="thumbnail_image_id"
                        control={control}
                      />
                      {/* {fieldFocus.name && (
                      <div style={instructionCss}>
                        <p style={{ marginBottom: "5px" }}>
                          Name your message template.
                        </p>
                        <p>
                          {" "}
                          Name can only have lowercase / numbers / underscore.
                        </p>
                      </div>
                    )} */}
                    </div>
                  )}
                  {/*  Body Content */}
                  <div>
                    <InputLabel sx={{ color: "#303535", margin: "5px 0" }}>
                      Body Content *
                    </InputLabel>
                    <div>
                      <Controller
                        render={({ field: { value, onChange } }) => (
                          <TextField
                            onKeyUp={handlePreview}
                            inputProps={{ ref: textFieldRef2, maxLength: 1024 }}
                            value={value}
                            onFocus={() => handleFocus("body_content")}
                            onBlur={() => {
                              // handlePreview();
                              handleBlur("body_content");
                            }}
                            onChange={(x) => {
                              onChange(x);
                              debouncedSearch(x);
                            }}
                            size="small"
                            fullWidth
                            multiline
                            rows={4}
                            sx={{
                              maxWidth: "388.58px",
                              width: "100%",
                              marginBottom: "10px",
                            }}
                            error={Boolean(errors?.body_content)}
                            helperText={
                              errors?.body_content && "Body Content is required"
                            }
                            InputProps={{
                              endAdornment: (
                                <InputAdornment
                                  position="end"
                                  style={{
                                    position: "absolute",
                                    bottom: "30px",
                                    right: "0",
                                  }}
                                >
                                  <div
                                    className={`rotate-button`}
                                    onClick={() =>
                                      toggleEmojiPicker("body_content")
                                    }
                                    style={{ cursor: "pointer" }}
                                  >
                                    <InsertEmoticonIcon />
                                  </div>
                                </InputAdornment>
                              ),
                            }}
                          />
                        )}
                        rules={{ required: true }}
                        name="body_content"
                        control={control}
                      />
                      <div
                        style={{
                          fontSize: "14px",
                          color: "gray",
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "flex-end",
                          flexDirection: "column",
                        }}
                      >
                        {watchAllFields.body_content
                          ? `${watchAllFields.body_content.length}/1024`
                          : "0/1024"}
                      </div>
                    </div>
                    {showBdyContentMenu && watchAllFields.body_content ? (
                      <div className="suggetionsCtr">
                        <MenuList>
                          {template_tags?.length > 0 ? (
                            template_tags.map((tag, index) => (
                              <MenuItem
                                key={index}
                                onClick={() =>
                                  handleSlecetSuggetion("body_content", tag)
                                }
                              >
                                {tag}
                              </MenuItem>
                            ))
                          ) : (
                            <p style={{ textAlign: "center" }}>Loadding...</p>
                          )}
                        </MenuList>
                      </div>
                    ) : (
                      ""
                    )}
                    {fieldFocus.body_content && (
                      <div style={instructionCss}>
                        <p style={{ marginBottom: "5px" }}>
                          Enter the text for your message.
                        </p>
                        <p style={{ marginBottom: "5px" }}>
                          To add variables to your text message ,use
                          placeholders like
                          &#123;&#123;1&#125;&#125;,&#123;&#123;2&#125;&#125;etc.
                        </p>
                        <p>
                          <strong> Formatting Instructions:</strong>
                        </p>
                        <ul>
                          <li>
                            <strong>Italicize text:</strong> _Italic text_{" "}
                          </li>
                          <li>
                            <strong>Bold text:</strong> *Bold text*
                          </li>
                          <li>
                            <strong>Monospace text / code:</strong> ```
                            Monospace text / code```
                          </li>
                          <li>
                            <strong>Strikethrough text:</strong> `~Strikethrough
                            text~`
                          </li>
                        </ul>
                      </div>
                    )}
                  </div>
                  {/*  Body Example */}
                  <div>
                    <InputLabel sx={{ color: "#303535", margin: "5px 0" }}>
                      Body Example
                    </InputLabel>
                    <Controller
                      render={({ field }) => (
                        <TextField
                          onKeyUp={handlePreview}
                          {...field}
                          onFocus={() => handleFocus("body_example")}
                          onBlur={() => {
                            // handlePreview();
                            handleBlur("body_example");
                          }}
                          size="small"
                          fullWidth
                          multiline
                          sx={{
                            maxWidth: "388.58px",
                            width: "100%",
                            marginBottom: "10px",
                          }}
                        />
                      )}
                      name="body_example"
                      control={control}
                    />

                    {fieldFocus.body_example && (
                      <div style={instructionCss}>
                        <p style={{ marginBottom: "5px" }}>
                          Add variables to Message.
                        </p>
                        <p style={{ marginBottom: "5px" }}>
                          Separate Variables with "," (comma) .
                        </p>
                      </div>
                    )}
                  </div>
                  {/*   Footer Content */}
                  <div>
                    <InputLabel sx={{ color: "#303535", margin: "5px 0" }}>
                      Footer Content
                    </InputLabel>
                    <div>
                      <Controller
                        render={({ field }) => (
                          <TextField
                            onKeyUp={handlePreview}
                            {...field}
                            onFocus={() => handleFocus("footer_content")}
                            onBlur={() => {
                              // handlePreview();
                              handleBlur("footer_content");
                            }}
                            size="small"
                            fullWidth
                            sx={{
                              maxWidth: "388.58px",
                              width: "100%",
                              marginBottom: "10px",
                            }}
                            inputProps={{ maxLength: 60 }}
                          />
                        )}
                        name="footer_content"
                        control={control}
                      />
                      <div
                        style={{
                          fontSize: "14px",
                          color: "gray",
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "flex-end",
                          flexDirection: "column",
                        }}
                      >
                        {watchAllFields.footer_content
                          ? `${watchAllFields.footer_content.length}/60`
                          : "0/60"}
                      </div>
                    </div>
                    {fieldFocus.footer_content && (
                      <div style={instructionCss}>
                        <p style={{ marginBottom: "5px" }}>
                          Add a short line of text to the bottom of your message
                          template. If you add the marketing opt-out button, the
                          associated footer will be shown here by default.
                        </p>
                      </div>
                    )}
                  </div>
                  {/* Category */}
                  {/* {watchAllFields.category?.value === "marketing" && (
                    <div>
                      <FormControlLabel
                        required
                        control={
                          <Checkbox
                            size="small"
                            checked={iscatalogue}
                            onChange={handleCatalogue}
                          />
                        }
                        label="catalogue "
                      />
                    </div>
                  )} */}
                  {/* custom buttons */}
                  {watchAllFields.template_type?.value === "catalogue" ? (
                    <>
                      <div>
                        <InputLabel sx={{ color: "#303535", margin: "5px 0" }}>
                          Button type
                        </InputLabel>
                        <Controller
                          control={control}
                          name={`buttons.0.type`}
                          render={({ field }) => (
                            <TextField
                              // onKeyUp={handlePreview}
                              {...field}
                              size="small"
                              fullWidth
                              defaultValue="Catalog"
                              placeholder="Button Type"
                              inputProps={{
                                maxLength: 25,
                              }}
                              InputProps={{
                                readOnly: true,
                              }}
                            />
                          )}
                        />
                      </div>{" "}
                      <div>
                        <InputLabel sx={{ color: "#303535", margin: "5px 0" }}>
                          Button text
                        </InputLabel>
                        <Controller
                          control={control}
                          name={`buttons.0.text`}
                          render={({ field }) => (
                            <TextField
                              // onKeyUp={handlePreview}
                              {...field}
                              size="small"
                              fullWidth
                              defaultValue="View Catalog"
                              placeholder="Button Text"
                              inputProps={{
                                maxLength: 25,
                              }}
                              InputProps={{
                                readOnly: true,
                              }}
                            />
                          )}
                        />
                      </div>
                    </>
                  ) : (
                    <div>
                      <InputLabel sx={{ color: "#303535", margin: "5px 0" }}>
                        Buttons
                      </InputLabel>
                      <Controller
                        render={({ field: { value, onChange } }) => (
                          <Autocomplete
                            // defaultValue={null}
                            style={{ maxWidth: "390px" }}
                            value={value}
                            options={btnOptions}
                            getOptionLabel={(option) =>
                              option ? option.name : ""
                            }
                            onChange={(e, data) => onChange(data)}
                            isOptionEqualToValue={(option, value) =>
                              option.id === value.id
                            }
                            renderInput={(params) => (
                              <TextField
                                onKeyUp={handlePreview}
                                {...params}
                                size="small"
                                error={Boolean(errors.buttons)}
                                onFocus={() => handleFocus("buttons")}
                                onBlur={() => {
                                  // handlePreview();
                                  handleBlur("buttons");
                                }}
                              />
                            )}
                          />
                        )}
                        name="buttons"
                        control={control}
                      />
                      {/* Note */}
                      {watchAllFields.buttons?.value === "call_to_action" ? (
                        <div style={noteCss}>
                          <p>
                            Create up to 2 buttons that let customers respond to
                            your message or take action.
                          </p>
                        </div>
                      ) : watchAllFields.buttons?.value === "quick_reply" ? (
                        <div style={noteCss}>
                          <p>
                            Create up to 3 buttons that let customers respond to
                            your message or take action.
                          </p>
                        </div>
                      ) : (
                        <></>
                      )}
                      {/*Note Ends*/}
                    </div>
                  )}

                  {watchAllFields.buttons?.value === "call_to_action" && (
                    <div>
                      <div
                        style={{
                          color: "#303535",
                          margin: "10px 0 10px 0",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          padding: "0 15px 0 0",
                        }}
                      >
                        <p>Call To Action</p>
                        <span>
                          <IconButton
                            sx={{
                              color: "#25d366",
                            }}
                            disabled={call_to_action.length <= 1 ? false : true}
                            onClick={() =>
                              call_to_action.length <= 1
                                ? appendCallToAction({
                                    buttonText: "",
                                    phone_number: "",
                                    url_type: "",
                                    url: "",
                                  })
                                : ""
                            }
                          >
                            <AddCircleIcon
                              style={{ height: "20px", width: "20px" }}
                            />
                          </IconButton>
                        </span>
                      </div>

                      {call_to_action.map((field, index) => (
                        <div
                          key={field.id}
                          style={{
                            // backgroundColor: "#efefef",
                            padding: "10px",
                            borderRadius: "4px",
                            marginBottom: "10px",
                            boxShadow:
                              "rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px",
                            // border: "1px solid #c2c2c2",
                            // box-shadow: ;
                          }}
                        >
                          <div style={custBtnCtrCss}>
                            <Controller
                              render={({ field: { value, onChange } }) => (
                                <Autocomplete
                                  style={{
                                    maxWidth: "310px",
                                    width: "100%",
                                    marginBottom: "10px",
                                  }}
                                  value={value ? value : null}
                                  options={typActOptions}
                                  getOptionLabel={(option) =>
                                    option ? option.name : ""
                                  }
                                  onChange={(e, data) => {
                                    onChange(data);
                                    const newData = [...typAct];
                                    newData[index] = data;
                                    setTypACT(newData);
                                  }}
                                  isOptionEqualToValue={(option, value) =>
                                    option.id === value.id
                                  }
                                  renderInput={(params) => (
                                    <TextField
                                      onKeyUp={handlePreview}
                                      {...params}
                                      size="small"
                                      placeholder="Type Of Action"
                                    />
                                  )}
                                />
                              )}
                              name={`call_to_action.${index}.typeOfAction`}
                              control={control}
                            />

                            <IconButton
                              sx={{
                                color: "#df1d5a",
                              }}
                              disabled={call_to_action.length <= 1}
                              onClick={() =>
                                call_to_action.length != 1
                                  ? removeCallToAction(index)
                                  : ""
                              }
                            >
                              <RemoveCircleIcon
                                style={{ height: "20px", width: "20px" }}
                              />
                            </IconButton>
                          </div>
                          <div style={custBtnCtrCss}>
                            <Controller
                              control={control}
                              name={`call_to_action.${index}.buttonText`}
                              render={({ field }) => (
                                <TextField
                                  onKeyUp={handlePreview}
                                  {...field}
                                  size="small"
                                  placeholder="Button Text"
                                  sx={{
                                    maxWidth: "310px",
                                    width: "100%",
                                    marginBottom: "10px",
                                  }}
                                  inputProps={{
                                    maxLength: 25,
                                  }}
                                />
                              )}
                            />
                            <div style={{ fontSize: "15px" }}>
                              {watchAllFields["call_to_action"][index]
                                ?.buttonText
                                ? `${watchAllFields["call_to_action"][index]?.buttonText.length}/25`
                                : `0/25`}
                            </div>
                          </div>
                          {typAct[index]?.value === "PHONE_NUMBER" ? (
                            <>
                              <div style={custBtnCtrCss}>
                                <Controller
                                  render={({ field: { onChange, value } }) => (
                                    <PhoneInput
                                      // value={phoneNo}
                                      value={value}
                                      onChange={onChange}
                                      specialLabel={""}
                                      country={"in"}
                                      inputStyle={{
                                        width: "100%",
                                        maxWidth: "310px",
                                        height: "40px",
                                        background: "#ffff",
                                        border: "solid 1px #c4c4c4",
                                        borderRadius: "4px",
                                      }}
                                      placeholder="Phone Number"
                                      inputProps={{
                                        name: "phone_number",
                                      }}
                                      buttonStyle={{
                                        border: "solid 1px #7C8686",
                                      }}
                                      dropdownClass={"countryDropDownCss"}
                                      // onBlur={handleValidation}
                                      // defaultErrorMessage={"Invalid Phone number"}
                                      isValid={(value, country) => {
                                        if (value.length > 10) {
                                          return true;
                                        } else {
                                          return false;
                                        }
                                      }}
                                      onKeyUp={handlePreview}
                                    />
                                  )}
                                  name={`call_to_action.${index}.phone_number`}
                                  control={control}
                                />
                              </div>
                            </>
                          ) : typAct[index]?.value === "URL" ? (
                            <>
                              {/* <div style={custBtnCtrCss}>
                              <Controller
                                render={({ field: { value, onChange } }) => (
                                  <Autocomplete
                                    style={{
                                      width: "310px",
                                      marginBottom: "10px",
                                    }}
                                    value={value ? value : null}
                                    options={urlTypeOptions}
                                    getOptionLabel={(option) =>
                                      option ? option.name : ""
                                    }
                                    onChange={(e, data) => onChange(data)}
                                    isOptionEqualToValue={(option, value) =>
                                      option &&
                                      value &&
                                      option?.id === value?.id
                                    }
                                    renderInput={(params) => (
                                      <TextField
                                      onKeyUp={handlePreview}
                                        {...params}
                                        size="small"
                                        placeholder="URL Type"
                                      />
                                    )}
                                  />
                                )}
                                name={`call_to_action.${index}.url_type`}
                                control={control}
                              />
                            </div> */}
                              <div style={custBtnCtrCss}>
                                <Controller
                                  control={control}
                                  name={`call_to_action.${index}.url`}
                                  render={({ field }) => (
                                    <TextField
                                      onKeyUp={handlePreview}
                                      {...field}
                                      size="small"
                                      placeholder="URL"
                                      sx={{
                                        wmaxWidth: "310px",
                                        width: "100%",
                                        marginBottom: "10px",
                                      }}
                                      // error={Boolean(
                                      //   errors?.call_to_action?.[index]
                                      //     ?.buttonText
                                      // )}
                                      // helperText={
                                      //   errors?.call_to_action?.[index]?.url &&
                                      //   `call_to_action.${index}.url_type error`
                                      // }
                                    />
                                  )}
                                />
                              </div>
                            </>
                          ) : (
                            ""
                          )}
                        </div>
                      ))}
                    </div>
                  )}
                  {/* Quick Reply dynamic fields */}
                  {watchAllFields.buttons?.value === "quick_reply" && (
                    <div>
                      <div
                        style={{
                          color: "#303535",
                          margin: "10px 0 10px 0",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          padding: "0 17px 0 0",
                        }}
                      >
                        <p>Quick Reply</p>
                        <span>
                          <IconButton
                            disabled={quickReply.length <= 2 ? false : true}
                            sx={{
                              color: "#25d366",
                            }}
                            onClick={() =>
                              quickReply.length <= 2
                                ? appendQuickReply({ buttonText: "" })
                                : ""
                            }
                          >
                            <AddCircleIcon
                              style={{ height: "20px", width: "20px" }}
                            />
                          </IconButton>
                        </span>
                      </div>

                      {quickReply.map((field, index) => (
                        <div
                          key={field.id}
                          style={{
                            // backgroundColor: "#efefef",
                            padding: "10px",
                            // borderRadius: "4px",
                            marginBottom: "10px",
                          }}
                        >
                          <div
                            style={{
                              // margin: "0 0 5px 0",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                              padding: "0 6px 0 0",
                            }}
                          >
                            <Controller
                              control={control}
                              name={`quickReply.${index}.buttonText`}
                              render={({ field }) => (
                                <TextField
                                  onKeyUp={handlePreview}
                                  {...field}
                                  size="small"
                                  placeholder="Button Text"
                                  onFocus={() => handleFocus(`buttons`)}
                                  onBlur={() => handleBlur("buttons")}
                                  sx={{
                                    maxWidth: "310px",
                                    width: "100%",
                                    marginBottom: "10px",
                                  }}
                                  // error={Boolean(
                                  //   errors?.quickReply?.[index]?.buttonText
                                  // )}
                                  // helperText={
                                  //   errors?.quickReply?.[index]?.buttonText &&
                                  //   `quickReply.${index}.buttonText is required`
                                  // }
                                  inputProps={{
                                    maxLength: 25,
                                  }}
                                />
                              )}
                            />
                            <div
                              style={{ fontSize: "15px", paddingLeft: "8px" }}
                            >
                              {watchAllFields["quickReply"][index]?.buttonText
                                ? `${watchAllFields["quickReply"][index]?.buttonText.length}/25`
                                : `0/25`}
                            </div>
                            <IconButton
                              sx={{
                                color: "#df1d5a",
                              }}
                              disabled={quickReply.length <= 1}
                              onClick={() =>
                                quickReply.length !== 1
                                  ? removeQuickReply(index)
                                  : ""
                              }
                            >
                              <RemoveCircleIcon
                                style={{ height: "20px", width: "20px" }}
                              />
                            </IconButton>
                          </div>
                        </div>
                      ))}
                    </div>
                  )}

                  {/* submit Buttons */}
                  <div style={{ display: "flex" }}>
                    <Button
                      variant="contained"
                      fullWidth
                      sx={BtnCSS}
                      type="submit"
                      name="send_to_draft"
                    >
                      Save
                    </Button>
                    {editData && (
                      <Button
                        variant="contained"
                        fullWidth
                        sx={BtnCSS}
                        type="submit"
                        name="send_to_review"
                      >
                        Send to Review
                      </Button>
                    )}
                  </div>
                </form>
              </FormProvider>
            </div>

            {/* preview */}

            <div
              style={{
                backgroundColor: "#f5f5f5",
                maxWidth: "400px",
                minWidth: "300px",
                width: "100%",
                minHeight: "200px",
                margin: "0 auto 0 auto",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "10px",
                flexDirection: "column",
                padding: "10px",
              }}
            >
              {showEmojiPicker && (
                <div
                  className="emojiPicker"
                  onKeyDown={(event) => {
                    if (event.key === "Escape") {
                      event.stopPropagation();
                      setShowEmojiPicker(false);
                    }
                  }}
                >
                  <EmojiPicker onEmojiClick={handleEmojiSelect} />
                </div>
              )}
              {previewLoading ? (
                <p>Loading...</p>
              ) : preview ? (
                <>
                  <div className="preview">
                    <div className="previewMCtr">
                      {selectedMedia === "image" && media ? (
                        <>
                          <Avatar
                            src={media}
                            alt="Image Preview"
                            style={{ marginTop: "10px" }}
                            name="header"
                          />
                          {preview?.body && (
                            <p style={{ whiteSpace: "pre-wrap" }}>
                              {parseFormattedText(preview?.body)}
                            </p>
                          )}

                          <p>{preview?.footer}</p>
                          {ifCallTOAction(preview.buttons)}
                        </>
                      ) : selectedMedia === "document" && media ? (
                        <>
                          <a
                            style={{
                              display: "flex",
                              alignItems: "center",
                              marginTop: "5px",
                              paddingTop: "5px",
                              borderTop: "1px solid #f2f2f2",
                              wordBreak: "break-all",
                              overflowWrap: "break-word",
                            }}
                            href={media}
                            target="_blank"
                            name="header"
                          >
                            <PictureAsPdfIcon />
                            {mediaName}
                          </a>
                          <p>{parseFormattedText(preview?.body)}</p>
                          <p>{preview.footer}</p>
                          {ifCallTOAction(preview.buttons)}
                        </>
                      ) : (
                        <>
                          {preview.header && preview.header.includes("http") ? (
                            <Avatar
                              src={preview.header}
                              alt="Image Preview"
                              style={{ marginTop: "10px" }}
                              name="header"
                            />
                          ) : (
                            <p>{preview.header}</p>
                          )}
                          <p>{parseFormattedText(preview?.body)}</p>

                          <p>{preview.footer}</p>
                          {ifCallTOAction(preview.buttons)}
                        </>
                      )}
                    </div>
                  </div>
                  {ifQuickreply(preview.buttons)}
                </>
              ) : (
                <>
                  <p>&nbsp;</p>
                </>
              )}
            </div>
          </Stack>
        </DialogContent>
      </Dialog>
      {ApiLoading && (
        <div className="overlay">
          <Spinner_2 style={{ width: "80px" }} />
        </div>
      )}
    </div>
  );
};
