import { useEffect } from "react";
import { useAuth } from "../Context/Auth";
import { playNotificationSound } from "../Components/Common";
import { useMediaQuery, useTheme } from "@mui/material";

export const useNotification = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  function checkNotificationPermission() {
    if ("Notification" in window) {
      if (Notification.permission === "granted") {
        console.log("Notification permission preGranted");
      } else {
        Notification.requestPermission().then((permission) => {
          if (permission === "granted") {
            console.log("Notification permission granted.");
          } else {
            console.log("Notification permission denied.");
          }
        });
      }
    } else {
      console.log("Notifications are not supported by this browser.");
    }
  }

  function setNotification(message) {
    if (isMobile === false) {
      if ("Notification" in window) {
        if (Notification.permission === "granted") {
          createNotification(message);
        } else {
          Notification.requestPermission().then((permission) => {
            if (permission === "granted") {
              createNotification(message);
            } else {
              console.log("Notification permission denied.");
            }
          });
        }
      }
    }
  }

  function createNotification(message) {
    if (message?.current_direction === "received") {
      const notification = new Notification(
        `Message from (${message?.contact?.name})`,
        {
          body:
            message?.content.length > 20
              ? `${message?.content.substring(0, 10)} ...`
              : `${message?.content}`,
        }
      );
      playNotificationSound();
    }
  }

  return {
    checkNotificationPermission,
    setNotification,
  };
};
