import Rectangle_82 from "../../Assets/Rectangle_82.png";
import Rectangle_83 from "../../Assets/Rectangle_83.png";
import Rectangle_84 from "../../Assets/Rectangle_84.png";
import Rectangle_85 from "../../Assets/Rectangle_85.png";
export const blogList = [
  {
    image: "blog1.jpg",
    heading:
      "WhatsApp Campaigns: A savvy way to reach your potential customers",
    description:
      "Marketing experimentation is now an essential strategy for retaining and luring new clients. Creative campaigns make brands memorable and also give an identity.",
    author: "Rahul Kumar",
    link: "https://medium.com/kuriyam-io/whatsapp-campaigns-a-savvy-way-to-reach-your-potential-customers-9006a27e62cb",
  },
  {
    image: "blog1.jpg",
    heading: "Email Marketing vs Whatsapp Marketing: Choose the Best",
    description:
      "The conversational platform has proposed a completely brand-new dimension to today’s business world.",
    author: "Rahul Kumar",
    link: "https://medium.com/kuriyam-io/email-marketing-vs-whatsapp-marketing-choose-the-best-e2a2698ac6df",
  },
  {
    image: "blog2.jpg",
    heading: "The advent of Whatsapp in E-business",
    description:
      "The COVID -19 pandemic has triggered a massive boom in the digital business. Even now, the situation is rapidly changing, and many people have become engrossed in the new era of online shopping. Businesses are now more concerned with reaching their target customers easily and affordably. But do we really have a solution?",
    author: "Jane Smith",
    link: "https://medium.com/kuriyam-io/7-useful-whatsapp-business-usecases-for-your-business-a07e42e0f458",
  },
  {
    image: "blog3.jpg",
    heading: "Older Blog 1",
    description: "This is the description for the older blog 1",
    author: "John Doe",
    link: "/blog3",
  },
  {
    image: "blog4.jpg",
    heading: "Older Blog 2",
    description: "This is the description for the older blog 2",
    author: "Jane Smith",
    link: "/blog4",
  },
];
